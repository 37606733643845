.search {
    overflow: hidden;

    $buttons-width: 77px;

    .container {
        display: block !important;
    }
    .container > .filters {
        width: 100% !important;
        padding-right: $buttons-width + 14px; //the padding size
        box-sizing: border-box;

        .field {
            clear: none;
        }

        input[type="text"] {
            width: 100% !important;
        }
    }
    .container > .buttons {
        display: block !important;
        width: $buttons-width !important;
        float: right;
        box-sizing: border-box;
    }
}
