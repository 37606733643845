.ui.horizontal.card {
    .sylius-regular-unit-price {
        color: #999;
    }
    .column.price {
        font-size: 1.5em;
        line-height: 1.2em;
    }
    .column.add-to-cart {
        text-align: right;
    }
}
