@include media-xs {
    .mobile.hidden,
    .tablet.only,
    .small.monitor.only,
    .large.monitor.only {
        display: none !important;
    }
}
@include media-sm {
    .mobile.only,
    .tablet.hidden,
    .small.monitor.only,
    .large.monitor.only {
        display: none !important;
    }
}
@include media-md {
    .mobile.only,
    .tablet.only,
    .small.monitor.hidden,
    .large.monitor.only {
        display: none !important;
    }
}
@include media-lg {
    .mobile.only,
    .tablet.only,
    .small.monitor.only,
    .large.monitor.hidden {
        display: none !important;
    }
}

