.ui.section.divider.featured {
    margin-top: 1rem;

    @include media-sm {
        margin-top: 2rem;
    }
}
.ui.equal.height {
    .column > .ui.segment {
        height: 100%;

    }
}
