label.one-option, select.one-option {
    display: none !important;
}

.summary-total {
    .ui.table:not(.unstackable) {
        tr {
            > td {
                @include media-xs {
                    display: inline-block !important;
                    width: 49.5% !important;
                    padding: 0 !important;
                }
            }
        }
    }
}

@include media-sm-only {
    .ui.container > .ui.stackable.grid.cart.summary > .column,
    .ui.container > .ui.stackable.grid.checkout.address > .column {
        width: 100% !important;
    }
}

.ui.action.coupon {
    display: flex;
    input[type="text"] {
        width: auto;
    }
}

.cart.summary {
    button[type="submit"].large {
        margin: 0 auto;
        display: block;
    }
}

.sylius-regular-unit-price {
    text-decoration: line-through;
}

//for most of the fields there's .field.required > label, but for Province (loaded through ajax), it's just label.required
label.required:after {
    display: inline-block;
    vertical-align: top;
    margin: -0.2em 0em 0em 0.2em;
    content: '*';
    color: #DB2828;
}

#sylius_checkout_address_shippingAddress_birthday {
    select {
        display: inline-block;
        width: 32.66%;
        &:last-child {
            margin-right: 0;
        }
        margin-right: 1%;
        &:last-child {
            margin-right: 0;
        }
    }
}

#sylius-cart-items {
    td.product {
        min-width: 279px;
        img, .content {
            display: block;
        }

        img {
            float: left;
            margin-right: .5em;
        }

        .content {
            margin-left: 2.8em;
        }
    }
}

#sylius-payments {
    p {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
}

#sylius-shipments {
    .message {
        height: 100%;
    }
}
