@include media-sm {
    .ui.horizontal.card {
        flex-direction: row;

        > * {
            flex: 1 0 50% !important;;
        }

        .image {
            border-radius: 0.28571429rem 0em 0em 0.28571429rem !important;
            border-right: 1px solid rgba(34, 36, 38, 0.0980392);
            max-width: 260px;

        }

        .content {
            border-top-width: 0;
            position: relative;

            .bottom {
                position: absolute;
                bottom: 1em;
                left: 1em;
                right: 1em;
            }
        }
    }
}

.ui.hidden.all {
    display: none !important;
}
