header {
    .logo {
        a {
            display: block;

            width: 100px;
            height: 90px;

            margin: 0 auto;

            @include media-sm {
                width: 150px;
                height: 140px;

                margin: 0 !important;
            }

            background: url('../images/logo.png') center no-repeat;
            background-size: 100%;
        }
    }
}

.ui.stackable.grid > .column:not(.row).logo {
    padding: 0 !important;
}

#menu {
    .mobile.only {
        width: 100%;
    }
    .mobile.hidden {
        display: flex;
    }

    .popup {
        left: 1em !important;
        right: 1em !important;

        &:before {
            right: 8em !important;
        }

        .item {
            color: rgba(0, 0, 0, .87);
        }
    }
}

.ui.sidebar {
    overflow: visible !important;

    .dropdown {
        .menu {
            top: 2.5em !important;
            left: 1em !important;
            right: 0 !important;

            border-top-right-radius: 0 !important;
        }
    }
}

.ui.large.stackable.menu {
    margin-bottom: 1rem;
}
