#footer {
    padding: .5em;

    @include media-sm {
        padding: 4em;
    }

    ul {
        padding: 0;
        list-style-type: none;

    }
    .inverted {
        a {
            &, &:visited, &:focus {
                display: block;
                color: rgba(255, 255, 255, 0.5);
                transition: 0.1s color ease;
                padding-bottom: 0.214286em;
            }
            &:hover, &:active {
                color: #fff;
            }
        }
    }
}
